// Chakra imports
import {
    Button,
    Flex,
    Grid,
    Text,
    useColorModeValue,
    Table,
    Tbody,
    Th,
    Tr,
    Thead,
    Input,
    IconButton,
    Tooltip 
} from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons';

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { Fragment, useState, useEffect } from "react";
import RowPurchaseRequestList from "components/Tables/RowPurchaseRequestList";
import ListRequest from "components/Tables/ListRequest.js";
import ReactPaginate from 'react-paginate';
import '../../theme/css/general.css'; 
import AuthApi from "../../api/auth";
import InputFilterTable from "components/Form/InputFilterTable";

function ListStatus() {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    
    const [titlePage, setTitlePage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const [listRequest, setListRequest] = useState([]);
    const [filters, setFilters] = useState({
        correlative: '',
        code: '',
        description: '',
        name_products: '',
        brand: '',
        model: '',
        status_name: '',
        resource: '',
        company: '',
        date_estimated_buys: '',
        date_estimated_return: '',
        date_estimated_delivery: '',
    });

    const [showFilters, setShowFilters] = useState({
        correlative: false,
        code: false,
        description: false,
        name_products: false,
        brand: false,
        model: false,
        status_name: false,
        resource: false,
        company: false,
        date_estimated_buys: false,
        date_estimated_return: false,
        date_estimated_delivery: false,
    });
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    
    const getListRequestStatus = async (idStatus, idProduct) => {
        
        try {
          let response = await AuthApi.getListRequestStatus(idStatus, idProduct);
          if (response.data.success === true) {
            setTitlePage(response.data.data.name_status)
            let arrayData = [];
              response.data.data.list.forEach(element => {
                console.log(element)
                arrayData.push(element);
              });
              setListRequest(arrayData);
          };
          
        } catch (err) {
          console.log(err);
        };
    };
    
    const handleViewRequest = (id) => {
        console.log(id)
    };

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.name]: e.value });
        setCurrentPage(0); 
    };

    const toggleFilter = (columnName) => {
        setShowFilters((prevShowFilters) => ({
            ...prevShowFilters,
            [columnName]: !prevShowFilters[columnName],
        }));
    };

    const filteredData = listRequest.filter(row => {
        return (
            row.correlative.toLowerCase().includes(filters.correlative.toLowerCase())
            && row.code.toLowerCase().includes(filters.code.toLowerCase())
            && row.description.toLowerCase().includes(filters.description.toLowerCase())
            && row.name_products.toLowerCase().includes(filters.name_products.toLowerCase())
            && row.brand.toLowerCase().includes(filters.brand.toLowerCase())
            && row.model.toLowerCase().includes(filters.model.toLowerCase())
            && row.status_name.toLowerCase().includes(filters.status_name.toLowerCase())
            && row.resource.toLowerCase().includes(filters.resource.toLowerCase())
            && row.company.toLowerCase().includes(filters.company.toLowerCase())
            && row.date_estimated_buys.toLowerCase().includes(filters.date_estimated_buys.toLowerCase())
            && row.date_estimated_return.toLowerCase().includes(filters.date_estimated_return.toLowerCase())
            && row.date_estimated_delivery.toLowerCase().includes(filters.date_estimated_delivery.toLowerCase())
        );
        //return ({   })
    });

    useEffect(() => {
        
        const url = window.location;
        const parts = url.href.split('/');
        const valueIdProduct = parts[parts.length - 1];
        const valueIdStatus = parts[parts.length - 2];

        if(valueIdStatus != 'list-status'){
            getListRequestStatus(valueIdStatus, valueIdProduct);
        }

    }, [])

     const offset = currentPage * itemsPerPage;
     const currentData = filteredData.slice(offset, offset + itemsPerPage);
     const pageCount = Math.ceil(listRequest.length / itemsPerPage);
 
     const handlePageClick = (data) => {
         setCurrentPage(data.selected);
     };

    return (
        <Flex direction="column" pt={{ base: "180px", md: "75px" }} >
            <Grid templateColumns={{ sm: "1fr" }}>

                <Card my={{ lg: "24px" }} me={{ lg: "24px" }} w={{ base: "100%", lg: "120%" }}>
                    <Flex direction="column">
                        <CardHeader py="12px">
                            <Text color={textColor} fontSize="lg" fontWeight="bold">
                                {titlePage}
                            </Text>
                        </CardHeader>
                        <CardBody>
                            <Flex direction="column" w="100%" mb="8" >
                                <Flex direction="row">
                                    <Table variant="simple" color={textColor}>
                                        <Thead>
                                            <Tr my=".8rem" pl="0px" color="gray.500">
                                                <Th></Th>
                                                <Th pl="0px" color="gray.500">
                                                    CORRELATIVO
                                                    {<InputFilterTable label={"Buscar por Correlativo"} nameInput={"correlative"} filter={filters.correlative} showFilters={showFilters.correlative}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    CÓDIGO
                                                    {<InputFilterTable label={"Buscar por Código"} nameInput={"code"} filter={filters.code} showFilters={showFilters.code}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    DESCRIPCIÓN
                                                    {<InputFilterTable label={"Buscar por Descripción"} nameInput={"description"} filter={filters.description} showFilters={showFilters.description}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    PRODUCTO
                                                    {<InputFilterTable label={"Buscar por Producto"} nameInput={"name_products"} filter={filters.name_products} showFilters={showFilters.name_products}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    MARCA
                                                    {<InputFilterTable label={"Buscar por Marca"} nameInput={"brand"} filter={filters.brand} showFilters={showFilters.brand}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    MODELO
                                                    {<InputFilterTable label={"Buscar por Modelo"} nameInput={"model"} filter={filters.model} showFilters={showFilters.model}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    ESTADO
                                                    {<InputFilterTable label={"Buscar por Estado"} nameInput={"status_name"} filter={filters.status_name} showFilters={showFilters.status_name}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    RECURSO
                                                    {<InputFilterTable label={"Buscar por Recurso"} nameInput={"resource"} filter={filters.resource} showFilters={showFilters.resource}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    EMPRESA
                                                    {<InputFilterTable label={"Buscar por Empresa"} nameInput={"company"} filter={filters.company} showFilters={showFilters.company}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    FECHA DE COMPRA
                                                    {<InputFilterTable label={"Buscar por Fecha de Compra"} nameInput={"date_estimated_buys"} filter={filters.date_estimated_buys} showFilters={showFilters.date_estimated_buys}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    FECHA DE ENTREGA
                                                    {<InputFilterTable label={"Buscar por Fecha de Entrega"} nameInput={"date_estimated_delivery"} filter={filters.date_estimated_delivery} showFilters={showFilters.date_estimated_delivery}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    FECHA DE DEVOLUCIÓN
                                                    {<InputFilterTable label={"Buscar por Fecha de Devolución"} nameInput={"date_estimated_return"} filter={filters.date_estimated_return} showFilters={showFilters.date_estimated_return}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {currentData.map((row, index) => {
                                                return (
                                                    <ListRequest
                                                        key={index}
                                                        id={row.id}
                                                        correlative={row.correlative}
                                                        code={row.code}
                                                        description={row.description}
                                                        product={row.name_products}
                                                        brand={row.brand}
                                                        model={row.model}
                                                        status={row.status_name}
                                                        resource={row.resource}
                                                        company={row.company}
                                                        date_buy={row.date_estimated_buys}
                                                        date_return={row.date_estimated_return}
                                                        date_delivery={row.date_estimated_delivery}
                                                    />
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                </Flex>
                                <ReactPaginate
                                    previousLabel={'Anterior'}
                                    nextLabel={'Siguiente'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </Flex>
                        </CardBody>
                    </Flex>
                </Card>
            </Grid>
            
        </Flex>
    );
}

export default ListStatus;