import {
    Button,
    Td,
    Text,
    Icon,
    Tr,
    useColorModeValue
  } from "@chakra-ui/react";
  import { Link } from 'react-router-dom';

  import {
    FaRegEdit,
    FaEye,
    FaRegTrashAlt
  } from "react-icons/fa";
  
  import React, { Fragment, useState, useEffect } from "react"; 
  
  function RowUsers(props) {
    
    const {id, correlative, ids, product, description, total, brand, status, onViewClick, resource, company, date_buy, date_return, date_delivery} = props;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");
    const [userDataProfile, setUserDataProfile] =  useState(JSON.parse(localStorage.getItem('user')))
  
    return (
      <Tr>
        <Td>
          <Link to={{
            pathname: `/admin/purchase-request/${id}`,
            state: { id }
          }}>
            <Button p="0px" bg="transparent" variant="no-hover">
              <Text
                fontSize="md"
                color="gray.400"
                fontWeight="bold"
                cursor="pointer"
              >
                <Icon as={FaEye} fontSize="lg" mb="12px" />
              </Text>
            </Button>
          </Link>
        </Td>
        <Td minWidth={{ sm: "150px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {correlative}
          </Text>
        </Td>
        <Td minWidth={{ sm: "200px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {description}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {product}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {brand}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {status}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {resource}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {company}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {date_buy}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {date_delivery}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {date_return}
          </Text>
        </Td>
      </Tr>
    );
  }
  
  export default RowUsers;
  