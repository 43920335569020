import {
    Button,
    Td,
    Text,
    Icon,
    Tr,
    useColorModeValue
  } from "@chakra-ui/react";
  import { Link } from 'react-router-dom';

  import {
    FaRegEdit,
    FaEye,
    FaRegTrashAlt
  } from "react-icons/fa";
  
  import React, { Fragment, useState, useEffect } from "react"; 
  
  function ListRequest(props) {
    
    const {id, correlative, code, description, product, brand, model, status, resource, company, date_buy, date_return, date_delivery} = props;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");
    const [userDataProfile, setUserDataProfile] =  useState(JSON.parse(localStorage.getItem('user')))
  
    const handleClickView = (id) => {
      //onViewClick(id);
      history.push({
        pathname: `/admin/purchase-request`,
        state: { id_purchase_request : id }
      });
    };
  
  
    return (
      <Tr>
        <Td>
        <Link to={{
          pathname: `/admin/purchase-request/${id}`,
          state: { id }
        }}>
            <Button p="0px" bg="transparent" variant="no-hover">
              <Text
                fontSize="md"
                color="gray.400"
                fontWeight="bold"
                cursor="pointer"
              >
                <Icon as={FaEye} fontSize="lg" mb="12px" />
              </Text>
            </Button>
            </Link>
        </Td>
        <Td minWidth={{ sm: "200px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {correlative}
          </Text>
        </Td>
        <Td minWidth={{ sm: "150px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {code}
          </Text>
        </Td>
        <Td minWidth={{ sm: "300px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {description}
          </Text>
        </Td>
        <Td minWidth={{ sm: "200px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {product}
          </Text>
        </Td>
        <Td minWidth={{ sm: "100px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {brand}
          </Text>
        </Td>
        <Td minWidth={{ sm: "100px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {model}
          </Text>
        </Td>
        <Td minWidth={{ sm: "100px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {status}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {resource}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {company}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {date_buy}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {date_delivery}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {date_return}
          </Text>
        </Td>
        {/*<Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {requesting_user}
          </Text>
        </Td>*/}
        
      </Tr>
    );
  }
  
  export default ListRequest;
  