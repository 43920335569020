// Chakra imports
import {
    Button,
    Flex,
    Grid,
    Text,
    useColorModeValue,
    Table,
    Tbody,
    Th,
    Tr,
    Thead,
    Input,
    IconButton,
    Tooltip 
} from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons';

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { Fragment, useState, useEffect } from "react";
import RowPurchaseRequestList from "components/Tables/RowPurchaseRequestList";
import ReactPaginate from 'react-paginate';
import '../../theme/css/general.css'; 
import AuthApi from "../../api/auth";
import InputFilterTable from "components/Form/InputFilterTable";

function Pending() {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    
    const [titlePage, setTitlePage] = useState('Solicitudes Pendientes');
    const [isModalOpen, setIsModalOpen] = useState(false);
    //const [errors, setErrors] = useState({ name_: false, email_: false, role_: false});
    const [listRequest, setListRequest] = useState([]);
    const [filters, setFilters] = useState({
        correlative: '',
        code: '',
        name_products: '',
        brand: '',
        status_name: ''
    });

    const [showFilters, setShowFilters] = useState({
        correlative: false,
        code: false,
        name_products: false,
        brand: false,
        status_name: false
    });
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    
    const getListPendind = async () => {
        try {
          let response = await AuthApi.getListPendind();
          if (response.data.success === true) {
       
            let arrayData = [];
              response.data.data.forEach(element => {
                arrayData.push(element);
              });
              setListRequest(arrayData);
          };
          
        } catch (err) {
          console.log(err);
        };
    };
    
    const handleViewRequest = (id) => {
        console.log(id)
    };

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.name]: e.value });
        setCurrentPage(0); 
    };

    const toggleFilter = (columnName) => {
        setShowFilters((prevShowFilters) => ({
            ...prevShowFilters,
            [columnName]: !prevShowFilters[columnName],
        }));
    };

    const filteredData = listRequest.filter(row => {
        return (
            row.correlative.toLowerCase().includes(filters.correlative.toLowerCase())
            && row.code.toLowerCase().includes(filters.code.toLowerCase())
            && row.name_products.toLowerCase().includes(filters.name_products.toLowerCase())
            && row.brand.toLowerCase().includes(filters.brand.toLowerCase())
            && row.status_name.toLowerCase().includes(filters.status_name.toLowerCase())
        );
        //return ({   })
    });

    useEffect(() => {
        getListPendind();
    }, [])

     const offset = currentPage * itemsPerPage;
     const currentData = filteredData.slice(offset, offset + itemsPerPage);
     const pageCount = Math.ceil(listRequest.length / itemsPerPage);
 
     const handlePageClick = (data) => {
         setCurrentPage(data.selected);
     };

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Grid templateColumns={{ sm: "1fr" }}>

                <Card my={{ lg: "24px" }} me={{ lg: "24px" }} w={{ base: "100%", lg: "100%" }}>
                    <Flex direction="column">
                        <CardHeader py="12px">
                            <Text color={textColor} fontSize="lg" fontWeight="bold">
                                {titlePage}
                            </Text>
                        </CardHeader>
                        <CardBody>
                            <Flex direction="column" w="100%" mb="8" >
                                <Flex direction="row">
                                    <Table variant="simple" color={textColor}>
                                        <Thead>
                                            <Tr my=".8rem" pl="0px" color="gray.500">
                                                <Th></Th>
                                                <Th pl="0px" color="gray.500">
                                                    CORRELATIVO
                                                    <InputFilterTable label={"Buscar por Correlativo"} nameInput={"correlative"} filter={filters.correlative} showFilters={showFilters.correlative}  captureValue={handleFilterChange} showFilter={toggleFilter}/>
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    CÓDIGO
                                                    <InputFilterTable label={"Buscar por Código"} nameInput={"code"} filter={filters.code} showFilters={showFilters.code}  captureValue={handleFilterChange} showFilter={toggleFilter}/> 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    PRODUCTO
                                                    <InputFilterTable label={"Buscar por Producto"} nameInput={"name_products"} filter={filters.name_products} showFilters={showFilters.name_products}  captureValue={handleFilterChange} showFilter={toggleFilter}/>
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    MARCA
                                                    <InputFilterTable label={"Buscar por Marca"} nameInput={"brand"} filter={filters.brand} showFilters={showFilters.brand}  captureValue={handleFilterChange} showFilter={toggleFilter}/>
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    ESTADO
                                                    <InputFilterTable label={"Buscar por Estado"} nameInput={"status_name"} filter={filters.status_name} showFilters={showFilters.status_name}  captureValue={handleFilterChange} showFilter={toggleFilter}/>
                                                </Th>
                                                
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {currentData.map((row, index) => {
                                                return (
                                                    <RowPurchaseRequestList
                                                        key={index}
                                                        id={row.id}
                                                        product={row.name_products}
                                                        correlative={row.correlative}
                                                        description={row.code}
                                                        total={null}
                                                        brand={row.brand}
                                                        status={row.status_name}
                                                        onViewClick={handleViewRequest}
                                                    />
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                </Flex>
                                <ReactPaginate
                                    previousLabel={'Anterior'}
                                    nextLabel={'Siguiente'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </Flex>
                        </CardBody>
                    </Flex>
                </Card>
            </Grid>
            
        </Flex>
    );
}

export default Pending;